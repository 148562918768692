import type {
	Directionality,
	Toast,
	ToastConfig,
	ToastOptions,
	ToastPosition,
	ToastType,
} from "./types";

export const TOAST_DEFAULT_DURATION = 10_000;
export const TOAST_CONTAINER_ID = "__toastmynuts__";
export const TOAST_WRAPPER_ID = "__toastmynuts__wrapper";
export const TOAST_CLASS = "__toastmynuts__toast";
export const TOAST_MESSAGE_CLASS = "__toastmynuts__message";
export const TOAST_CLOSE_BTN_CLASS = "__toastmynuts__close-btn";
export const TOAST_TYPE_ATTR_NAME = "data-toast-type";
export const TOAST_DEFAULT_POSITION = { x: "middle", y: "top" };
export const TOAST_MAX_VISIBLE_TOASTS = 3;

export const TOAST_TYPE_ICONS: {
	[key in ToastType]: string;
} = {
	neutral: "",
	promise: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width="24" height="24" style="shape-rendering: auto; display: block; background: transparent;" xmlns:xlink="http://www.w3.org/1999/xlink"><g><circle cx="50" cy="50" fill="none" stroke="currentColor" stroke-width="8" r="35" stroke-dasharray="164.93361431346415 56.97787143782138"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform></circle><g></g></g><!-- [ldio] generated by https://loading.io --></svg>`,
	info: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6"><path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" /></svg>`,
	error: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6"><path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" /></svg>`,
	success: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6"><path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" /></svg>`,
	warning: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6"><path fill-rule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" /></svg>`,
};

export function genRandomId(): string {
	return Math.random().toString(16).slice(2);
}

export function getDir(): Directionality {
	const dirAttr = document.documentElement.getAttribute("dir");

	if (dirAttr !== "rtl" && dirAttr !== "ltr") {
		return window.getComputedStyle(document.documentElement)
			.direction as Directionality;
	}

	return dirAttr;
}

/**
 * There should only be one instance of a
 * Toaster and this is not available externally.
 */
export class Toaster {
	private static _instance: Toaster;
	private static _toastDuration: number;
	private static _config: ToastConfig | undefined;
	/**
	 * We store the directionality of the document
	 * if it's not specified in the config
	 * so we don't repeatedly call getComputedStyle
	 */
	private static _defaultDir: Directionality;
	private _toasts: Toast[];
	/**
	 * To avoid a race condition for moving toasts upwards when removing a toast,
	 * where in the loop, they'd have the same reference to the toast array before the
	 * previous toasts were removed (they are still in an exit animation and yet to be removed),
	 * we add them to a toBeRemovedArray so we can remove them from the main toast array
	 */
	private _toastToBeRemoved: Toast[];
	private _toastContainerAbortControllers: (AbortController | undefined)[];

	private constructor() {
		this._toasts = [];
		this._toastToBeRemoved = [];
		this._toastContainerAbortControllers = [];
	}

	/**
	 * @throws {Error} If maxVisibleToasts is < 1 or toastDuration is < 100ms
	 */
	static getInstance(config?: ToastConfig): Toaster {
		if (!Toaster._instance) {
			Toaster._instance = new Toaster();
			Toaster._config = config;
			Toaster._toastDuration =
				config?.toastDuration ?? TOAST_DEFAULT_DURATION;

			if (!Toaster._config?.dir) {
				Toaster._defaultDir = getDir();
			}

			if (
				config?.maxVisibleToasts !== undefined &&
				config.maxVisibleToasts < 1
			) {
				throw new Error(
					"[ToastMyNuts] maxVisibleToasts must be a positive number",
				);
			}

			if (
				config?.toastDuration !== undefined &&
				config.toastDuration < 100
			) {
				throw new Error(
					"[ToastMyNuts] toastDuration must be at least 100 milliseconds",
				);
			}
		}

		return Toaster._instance;
	}

	/**
	 * @param param The position of the toast container
	 *
	 * @description Sets the position of the toast container,
	 * which in turn changes the position of the toasts.
	 */
	setPosition({ x, y }: ToastPosition): void {
		const toastContainer = document.getElementById(TOAST_CONTAINER_ID);

		if (toastContainer) {
			toastContainer.setAttribute("data-position-x", x);
			toastContainer.setAttribute("data-position-y", y);
		}

		if (!Toaster._config) {
			Toaster._config = {
				position: { x, y },
			};
		} else {
			Toaster._config.position = { x, y };
		}
	}

	/**
	 * @param isStackable
	 *
	 * @description Whether or not to stack toasts. Take note
	 * that this will only take into effect if all toasts are empty,
	 * since we are attaching listeners to the supposed toast container which
	 * is created when the first toast is added.
	 */
	setIsStackable(isStackable: boolean): void {
		if (!Toaster._config) {
			Toaster._config = {
				stackable: isStackable,
			};
		} else {
			Toaster._config.stackable = isStackable;
		}
	}

	/**
	 * @throws {[string, K]} If the promise fails,
	 * the promise toast will be updated to an error toast.
	 */
	addPromiseToast<T, K>(
		loadingMessage: string,
		successMessageStringOrCb: string | ((data: T | undefined) => string),
		errorMessageStringOrCb: string | ((err: K) => string),
		asyncOperation: Promise<T> | (() => Promise<T>),
		options?: ToastOptions,
	): Promise<[string, T]> {
		const toastId = this.addToast(loadingMessage, "promise", options);

		return new Promise<[string, T]>((resolve, reject) => {
			let isSuccess = false;
			let data: T | undefined;
			let err: K;

			(typeof asyncOperation === "function"
				? asyncOperation()
				: asyncOperation
			)
				.then((value) => {
					isSuccess = true;
					data = value;
					resolve([toastId, value]);
				})
				.catch((error) => {
					isSuccess = false;
					err = error;
					reject([toastId, error]);
				})
				// Since a finally block is always executed
				// after either then or catch, we can use it to update the toast.
				.finally(() => {
					let toast: Toast | undefined;
					let toastIdx: number | undefined;

					for (let i = 0; i < this._toasts.length; ++i) {
						if (this._toasts[i]._id === toastId) {
							toast = this._toasts[i];
							toastIdx = i;
						}
					}

					if (!toast) {
						throw new Error(
							"[ToastMyNuts] Could not find toast with ID " +
								toastId +
								" virtually",
						);
					}

					const toastContainer =
						document.getElementById(TOAST_CONTAINER_ID);

					if (!toastContainer) {
						throw new Error(
							"[ToastMyNuts] Could not find toast container with ID " +
								TOAST_CONTAINER_ID,
						);
					}

					const toastElement = document.getElementById(toastId);

					if (!toastElement) {
						throw new Error(
							"[ToastMyNuts] Could not find toast with ID " +
								toastId +
								" in the DOM",
						);
					}

					const toastRelativeContainer =
						toastElement.querySelector("div");
					if (!toastRelativeContainer) {
						throw new Error(
							"[ToastMyNuts] Could not find toast relative container",
						);
					}

					const toastMessageContainer =
						toastRelativeContainer.querySelector(
							"." + TOAST_MESSAGE_CLASS,
						);

					if (!toastMessageContainer) {
						throw new Error(
							"[ToastMyNuts] Could not find toast message container",
						);
					}

					let iconContainer;

					const existingIconContainer = toastElement.querySelector(
						".__toastmynuts__icon",
					);

					if (!existingIconContainer) {
						iconContainer = document.createElement("div");
						iconContainer.classList.add("__toastmynuts__icon");
					} else {
						existingIconContainer.innerHTML = "";
						iconContainer = existingIconContainer;
					}

					if (isSuccess) {
						toast._type = "success";
						toastElement.setAttribute(
							TOAST_TYPE_ATTR_NAME,
							"success",
						);
						toastMessageContainer.textContent =
							typeof successMessageStringOrCb === "function"
								? successMessageStringOrCb(data)
								: successMessageStringOrCb;
						iconContainer.innerHTML = TOAST_TYPE_ICONS.success;
					} else {
						toast._type = "error";
						toastElement.setAttribute(
							TOAST_TYPE_ATTR_NAME,
							"error",
						);
						toastMessageContainer.textContent =
							typeof errorMessageStringOrCb === "function"
								? errorMessageStringOrCb(err)
								: errorMessageStringOrCb;
						iconContainer.innerHTML = TOAST_TYPE_ICONS.error;
					}

					const [closeBtn, closeBtnAbortController] =
						this._createCloseBtn(toast._id);
					const closeOnSwipe =
						options?.closeOnSwipe || Toaster._config?.closeOnSwipe;

					toast._controllers.push(closeBtnAbortController);

					if (closeOnSwipe === true || closeOnSwipe === undefined) {
						toast._controllers.push(
							this._attachSwipeListenersToToast(toastElement),
						);
					}

					const isExpanded =
						toastContainer.getAttribute("data-expanded") === "true";

					if (!isExpanded) {
						toast._timeout = setTimeout(() => {
							this.removeToast(toastId);
						}, Toaster._toastDuration);
					}

					toastRelativeContainer.prepend(iconContainer);
					toastRelativeContainer.appendChild(closeBtn);

					const origFrontToastHeight =
						toastContainer.style.getPropertyValue(
							"--_front-toast-height",
						);

					toastElement.style.removeProperty("--_initial-height");
					toastContainer.style.removeProperty(
						"--_front-toast-height",
					);
					const origHeight = toastElement.style.height;

					toastElement.style.height = "auto";

					const packedHeight =
						toastElement.getBoundingClientRect().height;
					const packedHeightString = `${packedHeight}px`;

					toastElement.style.height = origHeight;

					if (
						toastElement.getAttribute("data-front-toast") === "true"
					) {
						toastContainer.style.setProperty(
							"--_front-toast-height",
							packedHeightString,
						);
					} else {
						toastContainer.style.setProperty(
							"--_front-toast-height",
							origFrontToastHeight,
						);
					}

					toastElement.style.setProperty(
						"--_initial-height",
						packedHeightString,
					);
					this._moveToastsDown(packedHeight, true);
				});
		});
	}

	addToast(
		message: string,
		type: ToastType = "neutral",
		options?: ToastOptions,
	): string {
		const [toastContainer, _] = this._getOrCreateToastContainer();
		const toastId = genRandomId();
		const [toastElement, toastListenersAbortController] =
			this._createToastElement(toastId, type, options);
		const toastRelativeContainer = document.createElement("div");
		const messageElement = this._createMessageElement(message);
		const controllersArray = [toastListenersAbortController];
		let closeBtn;
		let timeout;

		if (type !== "promise") {
			timeout = setTimeout(() => {
				this.removeToast(toastId);
			}, Toaster._toastDuration);

			const [closeBtnItem, closeBtnAbortControllerItem] =
				this._createCloseBtn(toastId);

			closeBtn = closeBtnItem;
			controllersArray.push(closeBtnAbortControllerItem);
		}

		if (type !== "neutral") {
			const iconContainer = document.createElement("div");

			iconContainer.innerHTML = TOAST_TYPE_ICONS[type];
			iconContainer.classList.add("__toastmynuts__icon");
			toastRelativeContainer.appendChild(iconContainer);
		}

		toastRelativeContainer.appendChild(messageElement);

		if (closeBtn) {
			toastRelativeContainer.appendChild(closeBtn);
		}

		toastElement.appendChild(toastRelativeContainer);
		toastContainer.prepend(toastElement);

		const originalHeight = toastElement.style.height;

		toastElement.style.height = "auto";

		const packedHeight = toastElement.getBoundingClientRect().height;
		const packedHeightString = `${packedHeight}px`;

		toastElement.style.height = originalHeight;
		toastContainer.style.setProperty(
			"--_front-toast-height",
			packedHeightString,
		);
		toastElement.style.setProperty("--_initial-height", packedHeightString);
		toastElement.removeAttribute("data-toast-state");
		this._moveToastsDown(packedHeight);

		const dateNow = Date.now();

		this._toasts.push({
			_message: message,
			_type: type,
			_controllers: controllersArray,
			_timeout: timeout,
			_duration: Toaster._toastDuration,
			_id: toastId,
			_created_on: dateNow,
			_timeout_created_on: options?.closeOnSwipe ? dateNow : undefined,
		});

		return toastId;
	}

	removeToast(toastId: string): void {
		let toast: Toast | undefined;
		let toastIdx: number | undefined;

		for (let i = 0; i < this._toasts.length; ++i) {
			if (this._toasts[i]._id === toastId) {
				toast = this._toasts[i];
				toastIdx = i;
			}
		}

		if (!toast || toastIdx === undefined) {
			throw new Error(
				"[ToastMyNuts] Could not find toast with ID " +
					toastId +
					" virtually",
			);
		}

		// We don't want to remove a toast that is a promise
		if (toast._type === "promise") {
			return;
		}

		const toastElement = document.getElementById(toast._id);

		if (!toastElement) {
			throw new Error(
				"[ToastMyNuts] Could not find toast with ID " +
					toastId +
					" in the DOM",
			);
		}

		const isExiting =
			toastElement.getAttribute("data-toast-state") === "closing";

		if (isExiting) {
			return;
		}

		while (toast._controllers.length !== 0) {
			toast._controllers.pop()?.abort();
		}

		const isHidden =
			toastElement.getAttribute("data-toast-state") === "hidden";

		if (toast._timeout !== undefined) {
			clearTimeout(toast._timeout);
		}

		if (isHidden) {
			this._removeToastPermanently(toastId, true);
			this._toasts.splice(toastIdx, 1);
			this._changeToastsZindex(toastIdx);

			return;
		}

		toastElement.setAttribute("data-toast-state", "closing");

		const toastElementTransitionDuration = parseFloat(
			getComputedStyle(toastElement).transitionDuration,
		);
		this._moveToastsUp(toastIdx);
		this._toastToBeRemoved.push(toast);
		// Remove the array so that, if a user instantly closes another toast,
		// the then call to _moveToastsUp will reference the updated state.
		this._toasts.splice(toastIdx, 1);
		this._changeToastsZindex(toastIdx);

		setTimeout(() => {
			this._removeToastPermanently(toastId, false);
		}, toastElementTransitionDuration * 1000);
	}

	/**
	 * Whether our toast did not have an exit animation
	 */
	private _removeToastPermanently(
		toastId: string,
		isRemovedInstantly: boolean,
	): void {
		// The toast idx might change already when this callback is called,
		// so we find it again.

		let toast: Toast | undefined;
		let toastIdx: number | undefined;

		if (isRemovedInstantly) {
			for (let i = 0; i < this._toasts.length; ++i) {
				if (this._toasts[i]._id === toastId) {
					toast = this._toasts[i];
					toastIdx = i;
				}
			}
		} else {
			for (let i = 0; i < this._toastToBeRemoved.length; ++i) {
				if (this._toastToBeRemoved[i]._id === toastId) {
					toast = this._toastToBeRemoved[i];
					toastIdx = i;
				}
			}
		}

		if (!toast) {
			throw new Error(
				"[ToastMyNuts] Could not find toast with ID " +
					toastId +
					" virtually",
			);
		}

		const toastElement = document.getElementById(toast._id);

		// TODO: Find a way for the toast Element to exist when toasts are removed
		// almost instantly one after the other. For some reason, it doesn't exist,
		// but we still successfully execute everything.
		toastElement?.remove();

		if (!isRemovedInstantly && toastIdx !== undefined) {
			this._toastToBeRemoved.splice(toastIdx, 1);
		}

		if (this._toasts.length === 0) {
			const toastWrapper = document.getElementById(TOAST_WRAPPER_ID);

			while (this._toastContainerAbortControllers.length !== 0) {
				this._toastContainerAbortControllers.pop()?.abort();
			}

			toastWrapper?.remove();
		}
	}

	/**
	 * Removes all timeouts in the toasts array
	 * and for each toast, changed their duration to
	 * the remaining time before they get removed.
	 * Used when expanding a stacked toast.
	 */
	private _removeTimeoutOfToasts() {
		const dateNow = Date.now();

		for (let i = 0; i < this._toasts.length; ++i) {
			const toast = this._toasts[i];

			if (toast._timeout) {
				const remainingTime =
					toast._timeout_created_on !== undefined
						? toast._duration -
							(dateNow - toast._timeout_created_on)
						: toast._duration;

				if (remainingTime <= 0) {
					continue;
				}

				clearTimeout(toast._timeout);
				toast._duration = remainingTime;
				toast._timeout = undefined;
			}
		}
	}

	/**
	 * Adds all timeouts in the toasts array.
	 * Used when stacking an expanded stacked toast.
	 */
	private _addTimeoutToToasts() {
		const dateNow = Date.now();

		for (let i = 0; i < this._toasts.length; ++i) {
			const toast = this._toasts[i];

			if (toast._type === "promise") {
				continue;
			}

			if (!toast._timeout) {
				toast._timeout_created_on = dateNow;
				toast._timeout = setTimeout(() => {
					this.removeToast(toast._id);
				}, toast._duration);
			}
		}
	}

	/**
	 * @param toastIdx The starting point from where we move the z indices of its sibling toasts.
	 */
	private _changeToastsZindex(toastIdx: number) {
		for (let i = toastIdx; i < this._toasts.length; ++i) {
			const toast = this._toasts[i];

			if (!toast) {
				continue;
			}

			/**
			 * @type {HTMLElement | null}
			 */
			const toastElement = document.getElementById(toast._id);

			if (!toastElement) {
				throw new Error(
					"[ToastMyNuts] Could not find toast with ID " +
						toast._id +
						" in the DOM",
				);
			}

			toastElement.style.setProperty("--_z-idx", i.toString());
		}
	}

	/**
	 * Used when a toast is removed
	 */
	private _moveToastsUp(start: number) {
		const maxVisibleToasts =
			Toaster._config?.maxVisibleToasts ?? TOAST_MAX_VISIBLE_TOASTS;

		for (let i = 0; i < start; ++i) {
			const toast = this._toasts[i];

			if (!toast) {
				throw new Error(
					"[ToastMyNuts] Could not find toast with index " +
						i +
						" virtually",
				);
			}

			/**
			 * @type {HTMLElement | null}
			 */
			const toastElement = document.getElementById(toast._id);

			if (!toastElement) {
				throw new Error(
					"[ToastMyNuts] Could not find toast with ID " +
						toast._id +
						" in the DOM",
				);
			}

			let heightOffset = 0;

			// Don't include the height of the toast that will be moved
			for (let j = i + 1; j < this._toasts.length; ++j) {
				// Don't include the height of the toast that is being removed
				if (j === start) {
					continue;
				}

				const toast = this._toasts[j];

				/**
				 * @type {HTMLElement | null}
				 */
				const toastElement = document.getElementById(toast._id);

				if (!toastElement) {
					throw new Error(
						"[ToastMyNuts] Could not find toast with ID " +
							toast._id +
							" in the DOM",
					);
				}

				const initialHeight = parseFloat(
					toastElement.style.getPropertyValue("--_initial-height"),
				);

				heightOffset += initialHeight;
			}

			toastElement.style.setProperty(
				"--_height-offset",
				`${heightOffset}px`,
			);

			const newLastToastIdx = this._toasts.length - 2;

			// -2 because we need to account for the toast that will be removed.
			toastElement.style.setProperty(
				"--_idx",
				(newLastToastIdx - i).toString(),
			);

			if (i === newLastToastIdx) {
				const toastContainer =
					document.getElementById(TOAST_CONTAINER_ID);

				if (!toastContainer) {
					throw new Error(
						"[ToastMyNuts] Could not find toast container with ID " +
							TOAST_CONTAINER_ID,
					);
				}

				const packedHeightString =
					toastElement.style.getPropertyValue("--_initial-height");

				toastContainer.style.setProperty(
					"--_front-toast-height",
					packedHeightString,
				);
				toastElement.setAttribute("data-front-toast", "true");
			}

			if (this._toasts.length - i <= maxVisibleToasts + 1) {
				if (
					toastElement.getAttribute("data-toast-state") === "hidden"
				) {
					toastElement.removeAttribute("data-toast-state");
					toastElement.removeAttribute("aria-hidden");
				}
			}
		}
	}

	/**
	 * Used when a new toast is added
	 */
	private _moveToastsDown(baseHeightOffset: number, isFromPromise?: boolean) {
		const maxVisibleToasts =
			Toaster._config?.maxVisibleToasts ?? TOAST_MAX_VISIBLE_TOASTS;

		for (let i = 0; i < this._toasts.length; ++i) {
			const toast = this._toasts[i];
			const toastElement = document.getElementById(toast._id);

			if (!toastElement) {
				throw new Error(
					"[ToastMyNuts] Could not find toast with ID " + toast._id,
				);
			}

			let heightOffset = isFromPromise ? 0 : baseHeightOffset;

			for (let j = i + 1; j < this._toasts.length; ++j) {
				const toast = this._toasts[j];
				const toastElement = document.getElementById(toast._id);

				if (!toastElement) {
					throw new Error(
						"[ToastMyNuts] Could not find toast with ID " +
							toast._id,
					);
				}

				let initialHeight = parseFloat(
					toastElement.style.getPropertyValue("--_initial-height"),
				);

				heightOffset += initialHeight;
			}

			toastElement.style.setProperty(
				"--_height-offset",
				`${heightOffset}px`,
			);

			if (!isFromPromise) {
				toastElement.style.setProperty(
					"--_idx",
					(this._toasts.length - i).toString(),
				);
				toastElement.removeAttribute("data-front-toast");

				if (this._toasts.length - i >= maxVisibleToasts) {
					if (
						toastElement.getAttribute("data-toast-state") !==
						"closing"
					) {
						toastElement.setAttribute("data-toast-state", "hidden");
						toastElement.setAttribute("aria-hidden", "true");
					}
				}
			}
		}
	}

	private _getOrCreateToastContainer(): [
		HTMLElement,
		AbortController | undefined,
	] {
		const toastContainer = document.getElementById(TOAST_CONTAINER_ID);

		if (!toastContainer) {
			const toastWrapper = document.createElement("div");
			const abortController = new AbortController();

			toastWrapper.id = TOAST_WRAPPER_ID;
			toastWrapper.setAttribute("aria-label", "Notifications (Alt + T)");
			toastWrapper.setAttribute("tabindex", "-1");

			const toastContainer = document.createElement("ol");

			toastContainer.id = TOAST_CONTAINER_ID;
			const isStackable =
				Toaster._config?.stackable === true ||
				Toaster._config?.stackable === undefined;

			toastContainer.setAttribute(
				"data-position-x",
				Toaster._config?.position?.x || TOAST_DEFAULT_POSITION.x,
			);
			toastContainer.setAttribute(
				"data-position-y",
				Toaster._config?.position?.y || TOAST_DEFAULT_POSITION.y,
			);
			toastContainer.setAttribute(
				"data-stackable",
				isStackable ? "true" : "false",
			);
			toastContainer.setAttribute(
				"data-expanded",
				isStackable ? "false" : "true",
			);

			if (
				Toaster._config?.theme === "dark" ||
				Toaster._config?.theme === "light"
			) {
				toastContainer.setAttribute(
					"data-theme",
					Toaster._config.theme,
				);
			}

			toastContainer.addEventListener(
				"pointerenter",
				() => {
					if (isStackable) {
						if (
							toastContainer.getAttribute(
								"data-did-toggle-expansion",
							) === "true"
						) {
							return;
						}

						toastContainer.setAttribute("data-expanded", "true");
						toastContainer.removeAttribute("data-should-stack");
					}

					this._removeTimeoutOfToasts();
				},
				{ passive: true, signal: abortController.signal },
			);
			toastContainer.addEventListener(
				"pointerleave",
				() => {
					if (isStackable) {
						if (
							toastContainer.getAttribute(
								"data-did-toggle-expansion",
							) === "true"
						) {
							return;
						}

						const children = toastContainer.children;

						for (let i = 0; i < children.length; ++i) {
							if (
								children[i].getAttribute("data-is-swiping") ===
								"true"
							) {
								toastContainer.setAttribute(
									"data-should-stack",
									"true",
								);
								return;
							}
						}

						toastContainer.setAttribute("data-expanded", "false");
					}
					this._addTimeoutToToasts();
				},
				{ passive: true, signal: abortController.signal },
			);

			if (isStackable) {
				window.addEventListener(
					"keydown",
					(e) => {
						if (e.altKey && e.code === "KeyT") {
							if (
								toastContainer.getAttribute(
									"data-did-toggle-expansion",
								) === "true"
							) {
								return;
							}

							const shouldExpand =
								toastContainer.getAttribute("data-expanded") ===
								"false";

							toastContainer.setAttribute(
								"data-expanded",
								shouldExpand ? "true" : "false",
							);
							toastContainer.setAttribute(
								"data-did-toggle-expansion",
								"true",
							);

							if (shouldExpand) {
								this._removeTimeoutOfToasts();
							} else {
								this._addTimeoutToToasts();
							}
						}
					},
					{ passive: true, signal: abortController.signal },
				);
				window.addEventListener(
					"keyup",
					(e) => {
						if (e.code === "KeyT") {
							toastContainer.setAttribute(
								"data-did-toggle-expansion",
								"false",
							);
						}
					},
					{ passive: true, signal: abortController.signal },
				);
			}

			toastWrapper.appendChild(toastContainer);
			document.body.appendChild(toastWrapper);

			this._toastContainerAbortControllers.push(abortController);

			return [toastContainer, abortController];
		}

		if (!(toastContainer instanceof HTMLOListElement)) {
			throw new Error(
				"[ToastMyNuts] Colliding ID " + TOAST_CONTAINER_ID + ".",
			);
		}

		return [toastContainer, undefined];
	}

	private _createMessageElement(message: string): HTMLDivElement {
		const messageElement = document.createElement("div");

		messageElement.textContent = message;
		messageElement.classList.add(TOAST_MESSAGE_CLASS);

		return messageElement;
	}

	private _createToastElement(
		toastId: string,
		type: ToastType,
		options?: ToastOptions,
	): [HTMLElement, AbortController | undefined] {
		const toastElement = document.createElement("li");

		toastElement.id = toastId;
		toastElement.classList.add(TOAST_CLASS);
		toastElement.style.setProperty("--_idx", "0");
		toastElement.style.setProperty(
			"--_z-idx",
			this._toasts.length.toString(),
		);
		toastElement.setAttribute(TOAST_TYPE_ATTR_NAME, type);
		toastElement.setAttribute("data-toast-state", "entering");
		toastElement.setAttribute("aria-live", "polite");
		toastElement.setAttribute("aria-atomic", "true");
		toastElement.setAttribute("data-front-toast", "true");
		toastElement.setAttribute(
			"dir",
			options?.dir || Toaster._config?.dir || Toaster._defaultDir,
		);

		if (options?.theme) {
			toastElement.setAttribute("data-theme", options.theme);
		}

		if (options?.richColors === true || Toaster._config?.richColors) {
			toastElement.setAttribute("data-rich-colors", "true");
		}

		let closeOnSwipe =
			options?.closeOnSwipe === undefined
				? Toaster._config?.closeOnSwipe
				: options.closeOnSwipe;
		let listenerAbortController;

		if (
			(closeOnSwipe === true || closeOnSwipe === undefined) &&
			type !== "promise"
		) {
			listenerAbortController =
				this._attachSwipeListenersToToast(toastElement);
		}

		return [toastElement, listenerAbortController];
	}

	/**
	 * Only call this once on a toast element.
	 */
	private _attachSwipeListenersToToast(
		toastElement: HTMLElement,
	): AbortController {
		const listenerAbortController = new AbortController();

		toastElement.addEventListener(
			"pointerdown",
			(evt) => {
				// @ts-ignore
				evt.target.setPointerCapture(evt.pointerId);

				toastElement.setAttribute("data-is-swiping", "true");
				toastElement.setAttribute(
					"data-dragstart-time",
					Date.now().toString(),
				);

				const originalPosition = evt.screenY;

				toastElement.style.setProperty(
					"--_original-position",
					originalPosition.toString(),
				);
			},
			{ signal: listenerAbortController.signal, passive: true },
		);

		window.addEventListener(
			"pointerup",
			(_evt) => {
				const swipeOffset = parseFloat(
					toastElement.style.getPropertyValue("--_swipe-offset"),
				);
				const absValue = Math.abs(swipeOffset);
				const dragStartTime = parseFloat(
					toastElement.getAttribute("data-dragstart-time") ?? "0",
				);
				const timeTaken = Date.now() - dragStartTime;
				const velocity = Math.abs(swipeOffset) / timeTaken;

				toastElement.setAttribute("data-is-swiping", "false");

				if (absValue >= 10 || velocity >= 0.5) {
					toastElement.setAttribute("data-exit-swipe", "true");
					this.removeToast(toastElement.id);
				} else {
					toastElement.style.setProperty("--_swipe-offset", "0");
					toastElement.style.opacity = "";
					toastElement.style.removeProperty("--_original-position");
					toastElement.removeAttribute("data-dragstart-time");
				}

				const toastContainer =
					document.getElementById(TOAST_CONTAINER_ID);

				if (!toastContainer) {
					throw new Error(
						"[ToastMyNuts] Could not find toast container with ID " +
							TOAST_CONTAINER_ID,
					);
				}

				if (
					toastContainer.getAttribute("data-should-stack") === "true"
				) {
					toastContainer.setAttribute("data-should-stack", "false");
					toastContainer.setAttribute("data-expanded", "false");
					this._addTimeoutToToasts();
				}
			},
			{ signal: listenerAbortController.signal, passive: true },
		);

		window.addEventListener(
			"pointermove",
			(evt) => {
				const isSwiping =
					toastElement.getAttribute("data-is-swiping") === "true";

				if (!isSwiping) {
					return;
				}
				const toastContainer =
					document.getElementById(TOAST_CONTAINER_ID);

				if (!toastContainer) {
					throw new Error(
						"[ToastMyNuts] Could not find toast container with ID " +
							TOAST_CONTAINER_ID,
					);
				}

				const verticalPosition =
					toastContainer.getAttribute("data-position-y");
				const isTop = verticalPosition === "top";
				const isBottom = verticalPosition === "bottom";
				const originalPosition = parseFloat(
					toastElement.style.getPropertyValue("--_original-position"),
				);
				const swipeOffset = originalPosition - evt.screenY;

				if (
					(isTop && swipeOffset < 0) ||
					(isBottom && swipeOffset > 0)
				) {
					return;
				} else if (!isTop && !isBottom) {
					throw new Error(
						"[ToastMyNuts] Invalid vertical position " +
							verticalPosition,
					);
				}

				const opacity = Math.max(
					1 - Math.abs(swipeOffset) / 35,
					0.25,
				).toFixed(2);

				toastElement.style.opacity = opacity;

				toastElement.style.setProperty(
					"--_swipe-offset",
					swipeOffset.toString(),
				);
			},
			{ signal: listenerAbortController.signal, passive: true },
		);

		return listenerAbortController;
	}

	private _createCloseBtn(
		toastId: string,
	): [HTMLButtonElement, AbortController] {
		const closeBtnAbortController = new AbortController();
		const closeBtn = document.createElement("button");

		closeBtn.type = "button";
		closeBtn.setAttribute("aria-label", "Close notification");
		closeBtn.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4"><path d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" /></svg>`;
		closeBtn.classList.add(TOAST_CLOSE_BTN_CLASS);
		closeBtn.addEventListener(
			"click",
			() => {
				this.removeToast(toastId);
			},
			{ signal: closeBtnAbortController.signal, passive: true },
		);

		return [closeBtn, closeBtnAbortController];
	}
}
